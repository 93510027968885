/**
 * This module serves as an entry point for cld resource packs
 *
 * 🔥 DO NOT ADD NEW IMPORTS INTO THIS FILE 🔥
 */
const langPack = require('@sqs/i18n-ui/packs/' + __TRANSLATION_LANGUAGE__ + '.json');

const sqsI18n = window.SQUARESPACE_I18N ?? { cldrResourcePacks: {} };

sqsI18n.cldrResourcePacks[__TRANSLATION_LANGUAGE__] = langPack;

window.SQUARESPACE_I18N = sqsI18n;